<template>
  <div>
    <section-banner v-for="(section, index) in sections" :key="index" :model="section"></section-banner>
  </div>
</template>

<script>
import SectionBanner from "@/components/Frontend/SectionBanner";

export default {
  name: 'Litigation',
  components: {
    SectionBanner
  },
  data() {
    return {
      sections: [
        {
          Name: "What we do",
          Title: "Litigation",
          Subtitle: "Lawyer Up",
          Description: "Litigation can be a daunting experience for anyone not acquainted with the legal system.",
          img: require("@/assets/img/Vuyani4.jpg"),
        },
        {
          TextColumn: true,
          Decoration: require("@/assets/img/Commas.svg"),
          TextColumn1: `<p>We understand this completely and can give you calm, careful counsel, throughout the process.<br></p>
            <p>We’ve got your back, and can help with:<br></p>
            <ul>
              <li>Having disputes referred to and assisting in Mediation proceedings</li>
              <li>Instituting and defending Court processes in respect of both trial and motion proceedings</li>
              <li>Having disputes referred to and assisting in Arbitration proceedings</li>
              <li>Bankruptcy<br>- Liquidation<br>- Business Rescue</li>
            </ul>`,
          TextColumn2: "<blockquote>Natalie and her team have consistently provided us with sound legal advice and have developed legal strategies that are time sensitive and focussed on the desired outcome. We would not hesitate in recommending NLA to anyone in need of their specialist services.”<br><br>- Mark Harris, Financial Manager, King Civil Engineering Contractors</blockquote>",
        },
        {
          Profiles: true,
          Name: "Talk to Us",
            Bios: [
            {
              Title: "Natalie Lubbe",
              Subtitle: "Director",
              Linkedin: "linkedin.com/in/natalielubbe",
              Email: "natalie@NLAteam.com",
              Tel: "+27 11 704 1563",
              img: require("@/assets/img/Natalie-bio.jpg"),
              left: true
            },
            {
              Title: "Vuyani Phenduka",
              Subtitle: "Director",
              Linkedin: "linkedin.com/in/vuyani-donovan-phenduka",
              Email: "vuyani@NLAteam.com",
              Tel: "+27 11 704 1563",
              img: require("@/assets/img/Vuyani-bio.jpg"),
              left: false
            },
            {
              Title: "Laura Sibande",
              Subtitle: "Candidate Attorney",
              Linkedin: "linkedin.com/in/laurasibanda",
              Email: "laura@NLAteam.com",
              Tel: "+27 11 704 1563",
              img: require("@/assets/img/Laura-bio.jpg"),
              left: true
            },
            {
              Title: "Touche Munyai",
              Subtitle: "Candidate Attorney",
              Linkedin: "linkedin.com/in/avhurengwitouchemunyai",
              Email: "touche@NLAteam.com",
              Tel: "+27 11 704 1563",
              img: require("@/assets/img/Touche-bio.jpg"),
              left: false,
              last: true
          }
          ],
        },
      ]
    }
  },
}
</script>

<style lang="scss" scoped>

</style>